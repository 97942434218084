import React, { useEffect, useState } from "react"
import Layout from "@/components/layout"
import SEO from "@/components/seo"
import { DetailHeader, DetailStart} from '@/components/Support/index'
import { useIntl } from "react-intl"
import {SpecsBox,CompatiBox,FAQBox} from '@/components/Ultra/index'
import { useGTMInit} from '@/hooks/useGTM.js'

const Index = () => {
    const intl = useIntl()
    useEffect(()=>{
        useGTMInit('support','air2ultra')
      },[])

    return (
        <Layout menuBlack={true}>
            <SEO page="Support" />
            <DetailHeader pro='air2ultra'/>
            <DetailStart updateLink='/support/update' manualsLink='/support/Air_2_Ultra_User_Manuals' />
            <SpecsBox noPaddingTop={true}/>
            <CompatiBox />
            <FAQBox />
          
        </Layout>
    )
}

export default Index